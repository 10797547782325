import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const PoliticaPrivacidad = () => (
	<Layout>
		<SEO title="Política de privacidad" />

		<div className="page-legal container">
			<h1>Política de privacidad y protección de datos</h1>

			<p>En cumplimiento de lo dispuesto en la Ley Orgánica 15/1999 de 13 de Diciembre, de Protección de Datos de Carácter Personal, VENTURAE CAP S.L. Comunica que:</p>
			<p>1. Con relación a los datos personales que se puedan aportar a través de las funcionalidades del Sitio Web www.dms.cat, le informamos que tales datos serán tratados única y exclusivamente para la correcta gestión de los servicios dispuestos a través de los mismos, por parte de VENTURAE CAP S.L.. Igualmente, VENTURAE CAP S.L. podrá tratar los datos para realizar envíos publicitarios y de información comercial, por diferentes medios inclusive los electrónicos, acerca de la empresa, sus actividades, productos y servicios, así como documentación de diversa naturaleza y por diferentes medios de información comercial de la empresa.</p>
			<p>2. Para el envío de newsletters y comunicaciones análogas, VENTURAE CAP S.L. utiliza tanto los datos obtenidos a través del Sitio Web www.dms.cat, como otras bases de datos propias que tienen entre sus finalidades la de realización de tales envíos, siempre tomando en consideración los requerimientos de la citada Ley Orgánica.</p>
			<p>3. Cuando los datos personales sean recabados a través del formulario, será necesario aportar, al menos, aquellos marcados con un asterisco, en otro caso VENTURAE CAP S.L. no podrá aceptar y gestionar el cumplimiento de relación establecida.</p>
			<p>4. Para ejercitar sus derechos de acceso, rectificación, cancelación y oposición sobre los datos, se deberá remitir escrito identificado con la referencia “Protección de Datos” a: VENTURAE CAP S.L., calle Tuset, nº 8, 3º 4ª, 08006, Barcelona.</p>
			<p>5. En el momento que se aporten datos, se podrá manifestar el deseo de NO recibir comunicaciones comerciales electrónicas por parte de VENTURAE CAP S.L.. Si no se desea recibir comunicaciones comerciales electrónicas, en el futuro, por parte de VENTURAE CAP S.L., se podrá manifestar tal deseo a través de la dirección de postal ya indicada o enviando un correo electrónico a info@dms.cat.</p>
			<p>6. Para el uso de nuestro sitio web es necesario la utilización de cookies. Las cookies se utilizan con la finalidad de poder identificar la sesión y la gestión de la cesta. Si usted lo desea puede configurar su navegador para ser avisado en pantalla de la recepción de cookies y para impedir la instalación de cookies en su disco duro. Por favor, consulte las instrucciones y manuales de su navegador para ampliar esta información.</p>


		</div>
	</Layout>
)

export default PoliticaPrivacidad
